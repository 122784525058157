import { useTranslation } from "react-i18next";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  handleStateChange,
  handleStateChangeMultiple,
} from "../../../../data/slices/filtersSlice";

const Fourniture = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    furnished_type,
    iron,
    microwave_heating_food,
    coffee_machine,
    oven,
    refrigerator,
    dishwasher,
    washing_machine,
  } = useSelector((state) => state.filters);

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  const handleBtnClickMultiple = (target, value) => {
    dispatch(
      handleStateChangeMultiple({
        target,
        value,
      })
    );
  };

  const { specifications } = useSelector((state) => state.properties);

  if (
    specifications?.furnished_type !== "1" &&
    specifications?.washing_machine !== "1" &&
    specifications?.dishwasher !== "1" &&
    specifications?.refrigerator !== "1" &&
    specifications?.oven !== "1" &&
    specifications?.coffee_machine !== "1" &&
    specifications?.iron !== "1" &&
    specifications?.microwave_heating_food !== "1" &&
    specifications?.oven !== "1"
  ) {
    return <></>;
  }

  return (
    <div className="fourniture-filters">
      <div className="btns-list">
        {specifications?.furnished_type === "1" && (
          <button
            className={`btn ${
              furnished_type?.includes("new_furnishing") && "btn-active"
            }`}
            onClick={() =>
              handleBtnClickMultiple("furnished_type", "new_furnishing")
            }
          >
            {t("filters.New_fourniture")}
          </button>
        )}
        {specifications?.furnished_type === "1" && (
          <button
            className={`btn ${
              furnished_type?.includes("used_furnishing") && "btn-active"
            }`}
            onClick={() =>
              handleBtnClickMultiple("furnished_type", "used_furnishing")
            }
          >
            {t("filters.used_fourniture")}
          </button>
        )}
      </div>

      <div className="checkbox-container">
        {specifications?.washing_machine === "1" && (
          <Checkbox
            label={t("filters.Washing_machine")}
            checked={washing_machine === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "washing_machine",
                washing_machine === "yes" ? "no" : "yes"
              )
            }
          />
        )}
        {specifications?.dishwasher === "1" && (
          <Checkbox
            label={t("filters.Dishwasher")}
            checked={dishwasher === "yes" ? true : false}
            onClick={() =>
              handleBtnClick("dishwasher", dishwasher === "yes" ? "no" : "yes")
            }
          />
        )}
      </div>

      <div className="checkbox-container">
        {specifications?.refrigerator === "1" && (
          <Checkbox
            label={t("filters.Refrigerator")}
            checked={refrigerator === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "refrigerator",
                refrigerator === "yes" ? "no" : "yes"
              )
            }
          />
        )}
        {specifications?.oven === "1" && (
          <Checkbox
            label={t("filters.Oven")}
            checked={oven === "yes" ? true : false}
            onClick={() =>
              handleBtnClick("oven", oven === "yes" ? "no" : "yes")
            }
          />
        )}
      </div>

      <div className="checkbox-container">
        {specifications?.coffee_machine === "1" && (
          <Checkbox
            label={t("filters.Coffe_machine")}
            checked={coffee_machine === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "coffee_machine",
                coffee_machine === "yes" ? "no" : "yes"
              )
            }
          />
        )}
        {specifications?.iron === "1" && (
          <Checkbox
            label={t("filters.Iron")}
            checked={iron === "yes" ? true : false}
            onClick={() =>
              handleBtnClick("iron", iron === "yes" ? "no" : "yes")
            }
          />
        )}
      </div>

      {specifications?.microwave_heating_food === "1" && (
        <div className="checkbox-container">
          <Checkbox
            label={t("filters.Microwave_heating_food")}
            checked={microwave_heating_food === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "microwave_heating_food",
                microwave_heating_food === "yes" ? "no" : "yes"
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default Fourniture;
